import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import blogo from './blogo.jpg';
import './Carousel.css'; // Ensure this file exists and contains the CSS
import hospitalBG from './hospitalBG.jpg';

const RotatingCircles = () => {
    const circlesRef = useRef([]);
    const blinkRef = useRef(null);

    useEffect(() => {
        const circles = circlesRef.current;

        circles.forEach((circle, index) => {
            const duration = 2;

            // Animate the rotation
            gsap.to(circle, {
                rotation: 360,
                duration: duration,
                repeat: -1,
                ease: 'none'
            });

            // Function to move the circle to a random position within the body
            const moveCircle = () => {
                const bodyWidth = window.innerWidth;
                const bodyHeight = window.innerHeight;

                const newX = Math.random() * (bodyWidth - circle.clientWidth);
                const newY = Math.random() * (bodyHeight - circle.clientHeight);

                gsap.to(circle, {
                    x: newX,
                    y: newY,
                    duration: 5 + Math.random() * 5, // Random duration between 5 and 10 seconds
                    ease: 'power1.inOut',
                    onComplete: moveCircle // Recursively call moveCircle on complete
                });
            };

            moveCircle();
        });

        // Blink animation for "Bhesajam"
        gsap.to(blinkRef.current, {
            duration: 1,
            repeat: -1,
            yoyo: true,
            ease: 'none',
            keyframes: {
                color: ['black', 'red', 'orange']
            }
        });
    }, []);

    return (
        <div className="circles-container py-2 relative ">
            <img src={hospitalBG} alt="logo" className='w-full h-full opacity-20 absolute top-0 left-0 ' />
            {Array.from({ length: 28 }).map((_, index) => (
                <div key={index} className="circle absolute" ref={el => (circlesRef.current[index] = el)}></div>
            ))}
            <div className="text-overlay relative z-10">
                <div className="flex flex-col justify-center items-center">
                    <h1 className='text-3xl font-bold text-center text-red-900 opacity-90' >
                        Building Trust, Empowering Success Every Step Forward
                    </h1>
                    <h1 className='text-5xl font-bold text-center py-4 opacity-90'>
                    <span   className="block text-red-900">
                            Bhesajam
                            <span className='block text-xl py-2'>We build trust</span>
                        </span>
                    </h1>
                    <p className='text-lg font-bold text-center text-red-900 opacity-90 '>Empower your healthcare team, optimize billing systems, revolutionize operations, and elevate patient care with our state-of-the-art Hospital Management System. Enhance efficiency, streamline workflows, and integrate advanced technology. Ensure comprehensive management, real-time data access, and improved performance, prioritizing patient satisfaction and care quality.</p><br />
                    <button className="signup-btn rounded-lg px-3 py-4 bg-gradient-to-r from-black to-red-500 text-white mb-2 whitespace-nowrap opacity-50 hover:opacity-100 ">
              <a href="https://wa.me/message/OMIG2SEK2XVVD1">GET STARTED</a>
            </button>
                </div>
            </div>
        </div>
    );
};

export default RotatingCircles;
