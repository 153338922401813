import React from 'react';
import ChatBot from 'react-simple-chatbot';
import { ThemeProvider } from 'styled-components';

const steps = [
    {
        id: '0',
        message: 'Hi!',
        trigger: '1',
    },
    {
        id: '1',
        user: true,
        trigger: '2',
    },
    {
        id: '2',
        message: 'Please write your name',
        trigger: '3',
    },
    {
        id: '3',
        user: true,
        trigger: '4',
    },
    {
        id: '4',
        message: 'We are Bhesajam, going to help you in transforming your hospital. Want to book a demo? Please reply with yes or no.',
        trigger: '5',
    },
    {
        id: '5',
        user: true,
        trigger: ({ value }) => {
            if (value.toLowerCase() === 'yes') {
                return '6';
            } else {
                return '8';
            }
        }
    },
    {
        id: '6',
        message: 'Please click the box below to contact us directly on WhatsApp',
        trigger: '7',
    },
    {
        id: '7',
        component: (
            <div>
               
                <a href="https://wa.me/message/OMIG2SEK2XVVD1" target="_blank" rel="noopener noreferrer" style={{ color: '#0F3789', textDecoration: 'none', backgroundColor: '#FF5733', padding: '10px', borderRadius: '5px', display: 'inline-block', marginTop: '10px' }}>Contact us on WhatsApp</a>
            </div>
        ),
        trigger: '7a',
    },
    {
        id: '7a',
        user: true,
        trigger: '9',
    },
    {
        id: '8',
        message: 'Thank you!',
        end: true,
    },
    {
        id: '9',
        message: 'Thank you! For Visiting Our Site',
        end: true,
    },
];

// Creating our own theme
const theme = {
    background: 'white',
    headerBgColor: 'black',
    headerFontSize: '20px',
    botBubbleColor: 'red',
    headerFontColor: 'white',
    botFontColor: 'white',
    userBubbleColor: 'black',
    userFontColor: 'white',
};

// Set some properties of the bot
const config = {
    floating: true,
};

const handleEnd = ({ steps, values }) => {
    const chatData = {
        name: values[1],
        demoResponse: values[2],
        mobileNumber: values[3],
    };

    // Send chat data to WhatsApp (replace YOUR_WHATSAPP_API with actual API endpoint)
 
};

const Chatbot = () => {
  return (
    <div>
        <ThemeProvider theme={theme}>
            <ChatBot
                headerTitle="Bhesajam!! How May I Help You?"
                steps={steps}
                {...config}
                handleEnd={handleEnd}
            />
        </ThemeProvider>
    </div>
  );
}

export default Chatbot;
