import React from 'react';

const Footer = () => {
  return (
    <div> 
    <div className="h-full w-full  bg- bg-red-900 opacity-90 flex flex-col justify-between"> 
      <section className="w-full flex-grow">
        <div className="max-w-[1200px] mx-auto py-5 grid sm:grid-cols-1 lg:grid-cols-3 gap-6">
          <div className="text-center text-white py-2">
            <h6 className="py-2 opacity-60 hover:text-white">GET AN EASY QUOTE</h6>
            <h2 className="text-2xl">Sign Up For Hospital Management System Today!</h2>
          </div>
          <div className="text-center text-white py-2">
            <h6 className="py-2 opacity-60 ">Starting at Only</h6>
            <h2 className="text-3xl">RS 40,000</h2>
          </div>
          <div className="text-center text-white py-10"> 
          <button className="signup-btn rounded-xl px-4 py-4 bg-red-500 text-white hover:bg-gradient-to-r from-black to-red-500">
              <a href="https://wa.me/message/OMIG2SEK2XVVD1">GET STARTED</a>
            </button>
          </div>
        </div>
        <div className="max-w-[1200px] mx-auto py-2 grid sm:grid-cols-1 lg:grid-cols-4 gap-6">
          <div className="  bg-red-500 ">
            <h1 className="text-center py-4 text-white">Bhesajam It Solution</h1>
            <p className="text-center text-white py-2">Our Concept is simple. Make the life easy of our valuable customers through IT Solution.</p>
            <div className="py-4 text-center space-x-6">
              <a href="https://www.instagram.com/itinfras?igsh=cnNnaDVsdDFxMG12">
                <i className="fa-brands fa-twitter text-white text-3xl" />
              </a>
              <a href="https://www.instagram.com/itinfras?igsh=cnNnaDVsdDFxMG12">
                <i className="fa-brands fa-facebook text-white text-3xl" />
              </a>
              <a href="https://www.instagram.com/itinfras?igsh=cnNnaDVsdDFxMG12">
                <i className="fa-brands fa-instagram text-white text-3xl" />
              </a>
            </div>
          </div>
         
          <div>
            <h1 className="lg:text-center text-white text-xl mb-3">Navigational</h1>
            <ul className="lg:text-center text-white space-y-3">
              <li className=" hover:opacity-100"><a href="#">Home</a></li>
              <li className=" hover:opacity-100"><a href="#">About Us</a></li>
               
              <li className=" hover:opacity-100"> <a href="#">Our Products</a></li>
            
            </ul>
          </div>
          <div>
            <h1 className="lg:text-center text-white text-xl mb-3">Our Offices</h1>
            <p className="text-white mb-4">  E-515, 1st floor, Sector-7, Dwarka, New Delhi-110077 India</p>
            
            <ul className="text-center space-y-4">
              <li className="flex items-center space-x-2 text-white text-center">
                <i className="fa-solid fa-phone" />
                <span>+91 9999 80 666 9</span>
              </li>
              <li className="flex items-center space-x-2 text-white text-center">
                <i className="fa-solid fa-phone" />
                <span>+91 11 45 661 661</span>
              </li>
              <li className="flex items-center space-x-2 text-white text-center">
                <i className="fa-regular fa-envelope" />
                <span>info@bhesajam.com</span>
              </li>
            </ul>
          </div>
        </div>
        
      </section>
       
    </div>
    <section className= " bg-red-500 opacity-90 py-4">
        <h1 className="text-center text-white">Copyright ©2024 All rights reserved | Designed by Akshar Consultancy Services Pvt Ltd</h1>
      </section>
    </div>
  );
};

export default Footer;
