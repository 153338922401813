import React from 'react';
import './Animation.css';

const middle2 = () => {
  return (
    <div> 
      <section id="services" className='bg-slate-50 bg-opacity-80'>
        <h1 className='text-center text-lg font-bold text-red-900 opacity-90'>
          Bhesajam Hospital Management System is a fully integrated & Configurable Healthcare Management ERP for Hospitals, Nursing Homes, Clinics, & other Healthcare facilities
        </h1>
        <div id="servicesCarousel" className="max-w-[1200px] mx-auto py-[50px] grid sm:grid-cols-1 lg:grid-cols-4 gap-6">
          <div className="relative shadow-lg p-4 text-center bg-gradient-to-r from-white to-orange-300 text-white    hover:opacity-100 service-box">
            <div className="group flex flex-col items-center justify-center z-10">
              <h3 className=" text-red-900 opacity-90 text-xl font-bold group-hover:text-black">User Friendly Interface</h3>
              <p className="text-gray-600 group-hover:text-black">Bhesajam Hospital System Software is designed with a user-friendly interface that makes it easy to manage hospital workflows.</p>
            </div>
          </div>
          <div className="relative shadow-lg p-4 text-center bg-gradient-to-r from-white to-red-300 text-white    hover:opacity-100 service-box">
            <div className="group flex flex-col items-center justify-center z-10">
              <h3 className=" text-red-900 opacity-90 text-xl font-bold group-hover:text-black">Integrated Overview</h3>
              <p className="text-gray-600 group-hover:text-black">Bhesajam is equipped with powerful features that simplify the management of the healthcare industry.</p>
            </div>
          </div>
          <div className="relative shadow-lg p-4 text-center bg-gradient-to-r from-white to-green-300 text-white hover:opacity-100 service-box">
            <div className="group flex flex-col items-center justify-center z-10">
              <h3 className=" text-red-900 opacity-90 text-xl font-bold group-hover:text-black">Stable, Safe & Secured</h3>
              <p className="text-gray-600 group-hover:text-black">Bhesajam is the best fit for Super Specialty Hospitals, Multi-Specialty Hospitals, General Hospitals.</p>
            </div>
          </div>
          <div className="relative shadow-lg p-4 text-center bg-gradient-to-r from-white to-blue-300 text-white    hover:opacity-100 service-box">
            <div className="group flex flex-col items-center justify-center z-10">
              <h3 className=" text-red-900 opacity-90 text-xl font-bold group-hover:text-black">Highly Customizable</h3>
              <p className="text-gray-600 group-hover:text-black">Bhesajam empowers healthcare operations with its customizable features, making it the ideal solution.</p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default middle2;
