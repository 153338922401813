import React from 'react';
import { XIcon } from '@heroicons/react/solid';
import Navbar from './Navbar';
import RotatingCircles from './RotatingCircles';
import Middle from './Middle';
import Contact from './Contact';
import Middle2 from './middle2'; // Ensure proper import (Capitalized)
import Chatbot from './Chatbot';
import WhatsAppIcon from './WhatsAppIcon';
import Footer from './Footer';

const Header = () => {
  return (
    <div className="relative flex flex-col items-center w-full">
      <div className="isolate flex items-center gap-x-6 overflow-hidden bg-gray-50 px-6 py-2.5 sm:px-3.5 sm:before:flex-1 w-full z-40 fixed lg:block hidden">
        <div
          className="absolute left-[max(-7rem,calc(50%-52rem))] top-1/2 -z-10 -translate-y-1/2 transform-gpu blur-2xl"
          aria-hidden="true"
        >
          <div
            className="aspect-[577/310] w-[36.0625rem] bg-gradient-to-r from-[#000000] to-[#FF0000] opacity-50"
            style={{
              clipPath:
                'polygon(74.8% 41.9%, 97.2% 73.2%, 100% 34.9%, 92.5% 0.4%, 87.5% 0%, 75% 28.6%, 58.5% 54.6%, 50.1% 56.8%, 46.9% 44%, 48.3% 17.4%, 24.7% 53.9%, 0% 27.9%, 11.9% 74.2%, 24.9% 54.1%, 68.6% 100%, 74.8% 41.9%)',
            }}
          />
        </div>
        <div
          className="absolute left-[max(45rem,calc(50%+8rem))] top-1/2 -z-10 -translate-y-1/2 transform-gpu blur-2xl"
          aria-hidden="true"
        >
          <div
            className="aspect-[577/310] w-[36.0625rem] bg-gradient-to-r from-[#000000] to-[#FF0000] opacity-50"
            style={{
              clipPath:
                'polygon(74.8% 41.9%, 97.2% 73.2%, 100% 34.9%, 92.5% 0.4%, 87.5% 0%, 75% 28.6%, 58.5% 54.6%, 50.1% 56.8%, 46.9% 44%, 48.3% 17.4%, 24.7% 53.9%, 0% 27.9%, 11.9% 74.2%, 24.9% 54.1%, 68.6% 100%, 74.8% 41.9%)',
            }}
          />
        </div>
        <div className="flex flex-wrap items-center justify-center gap-x-4 gap-y-2">
          <p className="text-sm leading-6 text-gray-900 ">
            <strong className="font-semibold ">Bhesajam</strong>
            <svg viewBox="0 0 2 2" className="mx-2 inline h-0.5 w-0.5 fill-current" aria-hidden="true">
              <circle cx={1} cy={1} r={1} />
            </svg>
            Streamline hospital operations with our innovative, efficient, and secure management system.
          </p>
          <a
            href="https://wa.me/message/OMIG2SEK2XVVD1"
            className="flex-none rounded-full bg-gray-900 px-3.5 py-1 text-sm font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
          >
            Book Demo <span aria-hidden="true">&rarr;</span>
          </a>
        </div>
        <div className="flex flex-1 justify-end">
          <button type="button" className="-m-3 p-3 focus-visible:outline-offset-[-4px]">
            <span className="sr-only">Dismiss</span>
          {  /*<XIcon className="h-5 w-5 text-gray-900" aria-hidden="true" />*/}
          </button>
        </div>
      </div>
      <div className="lg:mt-12 sm:mt-24 fixed w-full top-0 z-50 bg-white shadow-md">
        <Navbar />
      </div>
      <div className="lg:mt-12 sm:mt-72">
        <section id="home">
          {/* Your home content goes here */}
        </section>
        <section id="about-us">
          <RotatingCircles />
        </section>
        <section id="our-products">
          <Middle />
        </section>
        <section id="contact-us">
          <Contact />
        </section>
        <Middle2 /> {/* Ensure component name is properly capitalized */}
        <Footer />
        <Chatbot />
        <WhatsAppIcon />
      </div>
    </div>
  );
};

export default Header;
