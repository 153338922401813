import React from 'react';

const Contact = () => {
  // Function to handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    const scriptURL = 'https://script.google.com/macros/s/AKfycbwHaPI7SpF-9WWThpqm6Dx8pRZQ4unOBMtNuRg5UdC95xIfcgpXJjir_xYFrRidqIZgIg/exec';
    const form = e.target;
    const formData = new FormData(form);

    try {
      const response = await fetch(scriptURL, {
        method: 'POST',
        body: formData,
      });
      console.log('Form submitted successfully:', response);
      clearFormFields(); // Clear form fields after successful submission
      // Optionally, add code to show success message or redirect user
    } catch (error) {
      console.error('Error submitting form:', error);
      // Optionally, add code to handle errors, e.g., show an error message
    }
  };

  // Function to clear form fields
  const clearFormFields = () => {
    document.getElementById('contactForm').reset(); // Assuming 'contactForm' is your form ID
  };

  return (
    <div>
      <section id="contact"  >
        <div className="mx-auto max-w-7xl px-4 py-16 sm:px-6 lg:px-8 lg:py-20">
          <div className="mb-4">
            <div className="mb-6 max-w-3xl text-center sm:text-center md:mx-auto md:mb-12">
              <h2 className="font-heading mb-4 font-bold tracking-tight text-red-900 text-3xl sm:text-5xl opacity-90">
                Get in Touch
              </h2>
              <p className="mx-auto mt-4 max-w-3xl text-xl text-gray-900 dark:text-slate-600">
                Welcome to Bhesajam, Contact us today
              </p>
            </div>
          </div>
          <div className="flex items-stretch justify-center">
            <div className="grid md:grid-cols-2">
              <div className="h-full pr-6">
                <p className="mt-3 mb-12 text-lg text-gray-900 dark:text-slate-600">
                  Connect with us today for expert solutions tailored to your needs. Our team is ready to assist you. Let's achieve success together. Contact us now!
                </p>
                <ul className="mb-6 md:mb-0">
                  <li className="flex">
                    <div className="flex h-10 w-10 items-center justify-center rounded bg-gradient-to-r from-[#000000] to-[#FF0000] opacity-50 text-white">
                      <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="h-6 w-6">
                        <path d="M9 11a3 3 0 1 0 6 0a3 3 0 0 0 -6 0" />
                        <path d="M17.657 16.657l-4.243 4.243a2 2 0 0 1 -2.827 0l-4.244 -4.243a8 8 0 1 1 11.314 0z">
                        </path>
                      </svg>
                    </div>
                    <div className="ml-4 mb-4">
                      <h3 className="mb-2 text-lg font-medium leading-6 text-gray-900 dark:text-black opacity-80">Our Address</h3>
                      <p className="text-gray-900 dark:text-slate-600">E-515, 1st floor, Sector-7, Dwarka </p>
                      <p className="text-gray-900 dark:text-slate-600">New Delhi-110077 India</p>
                    </div>
                  </li>
                  <li className="flex">
                    <div className="flex h-10 w-10 items-center justify-center rounded bg-gradient-to-r from-[#000000] to-[#FF0000] opacity-50 text-white">
                      <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="h-6 w-6">
                        <path d="M5 4h4l2 5l-2.5 1.5a11 11 0 0 0 5 5l1.5 -2.5l5 2v4a2 2 0 0 1 -2 2a16 16 0 0 1 -15 -15a2 2 0 0 1 2 -2">
                        </path>
                        <path d="M15 7a2 2 0 0 1 2 2" />
                        <path d="M15 3a6 6 0 0 1 6 6" />
                      </svg>
                    </div>
                    <div className="ml-4 mb-4">
                      <h3 className="mb-2 text-lg font-medium leading-6 text-black opacity-80">Contact</h3>
                      <p className="text-gray-900 dark:text-slate-600">Mobile: +91 999-980-6669</p>
                      <p className="text-gray-900 dark:text-slate-600">Mail: info@bhesajam.com</p>
                    </div>
                  </li>
                  <li className="flex">
                    <div className="flex h-10 w-10 items-center justify-center rounded bg-gradient-to-r from-[#000000] to-[#FF0000] opacity-50 text-white">
                      <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="h-6 w-6">
                        <path d="M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0" />
                        <path d="M12 7v5l3 3" />
                      </svg>
                    </div>
                    <div className="ml-4 mb-4">
                      <h3 className="mb-2 text-lg font-medium leading-6 text-black opacity-80">Working hours</h3>
                      <p className="text-gray-900 dark:text-slate-600">Monday - Friday: 10:00 - 18:00</p>
                      <p className="text-gray-900 dark:text-slate-600">Saturday &amp; Sunday: 10:00 - 13:00</p>
                    </div>
                  </li>
                </ul>
              </div>
              <div className="card h-fit max-w-6xl p-5 md:p-12" id="form">
                <h2 className="mb-4 text-2xl font-bold text-red-900 opacity-90">Ready to Get Started?</h2>
                <form id="contactForm" onSubmit={handleSubmit}>
                  <div className="mb-6">
                    <div className="mx-0 mb-1 sm:mb-4">
                      <label htmlFor="name" className="pb-1 text-xs uppercase tracking-wider"></label>
                      <input type="text" id="name" autoComplete="given-name" placeholder="Your name" className="mb-2 w-full rounded-md border border-gray-400 py-2 pl-2 pr-4 shadow-md dark:text-gray-600 sm:mb-0" name="Name" />
                    </div>
                    <div className="mx-0 mb-1 sm:mb-4">
                      <label htmlFor="email" className="pb-1 text-xs uppercase tracking-wider"></label>
                      <input type="email" id="email" autoComplete="email" placeholder="Your email address" className="mb-2 w-full rounded-md border border-gray-400 py-2 pl-2 pr-4 shadow-md dark:text-gray-600 sm:mb-0" name="Email" />
                    </div>
                    <div className="mx-0 mb-1 sm:mb-4">
                      <label htmlFor="number" className="pb-1 text-xs uppercase tracking-wider"></label>
                      <input type="tel" id="number" autoComplete="tel" placeholder="Your contact number" className="mb-2 w-full rounded-md border border-gray-400 py-2 pl-2 pr-4 shadow-md dark:text-gray-600 sm:mb-0" name="Contact" />
                    </div>
                    <div className="mx-0 mb-1 sm:mb-4">
                      <label htmlFor="message" className="pb-1 text-xs uppercase tracking-wider"></label>
                      <textarea id="message" rows={4} placeholder="How can we assist you?" className="mb-2 w-full rounded-md border border-gray-400 py-2 pl-2 pr-4 shadow-md dark:text-gray-600 sm:mb-0" name="Message" defaultValue={""} />
                    </div>
                  </div>
                  <div className="flex items-center">
                    <button type="submit" className=" bg-gradient-to-r from-[#000000] to-[#FF0000] opacity-50 text-white font-bold py-2 px-6 rounded focus:outline-none focus:shadow-outline hover:opacity-100">Submit</button>
                    <p className="ml-3 text-gray-700 dark:text-gray-500" />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Contact;
