import React, { useState } from 'react';
import whatsappLogo from './whatsapp_logo.png'; // Replace with your actual path

const WhatsAppIcon = () => {
    const [isDragging, setIsDragging] = useState(false);
    const [offsetX, setOffsetX] = useState(0);
    const [offsetY, setOffsetY] = useState(0);

    const handleMouseDown = (e) => {
        setIsDragging(true);
        setOffsetX(e.clientX - e.target.getBoundingClientRect().left);
        setOffsetY(e.clientY - e.target.getBoundingClientRect().top);
    };

    const handleMouseMove = (e) => {
        if (isDragging) {
            const whatsappLogo = document.getElementById('whatsappLogo');
            whatsappLogo.style.position = 'absolute';
            whatsappLogo.style.left = `${e.clientX - offsetX}px`;
            whatsappLogo.style.top = `${e.clientY - offsetY}px`;
            whatsappLogo.style.bottom = 'auto';
            whatsappLogo.style.right = 'auto';
        }
    };

    const handleMouseUp = () => {
        setIsDragging(false);
    };

    const handleClick = () => {
        const message = encodeURIComponent('Hi'); // Message to be pre-filled
        const phoneNumber = '+919999806669'; // WhatsApp phone number
        const whatsappUrl = `https://wa.me/${phoneNumber}?text=${message}`;
        window.open(whatsappUrl, '_blank');
    };

    return (
        <section className="relative">
            <img
                src={whatsappLogo}
                alt="WhatsApp Logo"
                id="whatsapp_logo"
                className="fixed cursor-pointer z-50 w-12 h-12"
                style={{ bottom: '20px', left: '20px' }}
                onMouseDown={handleMouseDown}
                onMouseMove={handleMouseMove}
                onMouseUp={handleMouseUp}
                onClick={handleClick}
            />
        </section>
    );
};

export default WhatsAppIcon;
