import React, { useState } from 'react';
import '@fortawesome/fontawesome-free/css/all.min.css';
import blogo from './blogo.jpg';
import Middle from './Middle';

const Navbar = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
    setDropdownOpen(false); // Close dropdown when main menu is toggled
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  return (
    <header className="w-full  top-0 bg-white z-50 shadow-md">
      <nav className="top-0 left-0 z-50 flex items-center justify-between lg:justify-between border-gray-300 border-b bg-white w-full">
        <div className="flex items-center">
          <div className="logo ml-2 lg:ml-12 w-28 lg:w-28 md:w-1/2 flex-shrink-0">
            <img src={blogo} alt="Logo" className="w-full h-auto md:w-96" />
          </div>
        </div>
        <div className={`dropdown-menu ${menuOpen ? '' : 'hidden'} lg:relative lg:flex lg:flex-row flex flex-col justify-center items-center text-center`}>
          <ul className="flex flex-col items-center gap-6 lg:flex-row lg:mr-20 lg:gap-14 text-black">
            <li className="hover:text-red-900 text-base whitespace-nowrap">
              <a href="#home">Home</a>
            </li>
            <li className="hover:text-red-900 text-base whitespace-nowrap">
              <a href="#about-us">Company</a>
            </li>
            <li className="hover:text-red-900 text-base lg:whitespace-nowrap relative">
              <a href="#" className="hover:text-red-900 text-base lg:whitespace-nowrap" onClick={toggleDropdown}>
                Products <i className="fas fa-angle-down ml-1"></i>
              </a>
              {/* Dropdown menu */}
              <div id="dropdown" className={`absolute left-0 mt-2 bg-white border border-gray-200 rounded-lg shadow-lg ${dropdownOpen ? 'block' : 'hidden'} lg:py-2 lg:px-4`}>
                <ul className="py-2 text-sm text-gray-700">
                <li>
                    <a href="#Patient" className="block px-4 py-2 hover:bg-gray-100">Patient Appointment</a>
                  </li>
                  <li>
                    <a href="#OPD" className="block px-4 py-2 hover:bg-gray-100">OPD</a>
                  </li>
                  <li>
                    <a href="#IPD" className="block px-4 py-2 hover:bg-gray-100">IPD</a>
                  </li>
                  <li>
                    <a href="#Radiology" className="block px-4 py-2 hover:bg-gray-100">Radiology</a>
                  </li>
                  <li>
                    <a href="#OT" className="block px-4 py-2 hover:bg-gray-100">OT Module</a>
                  </li>
                  <li>
                    <a href="#Pathology" className="block px-4 py-2 hover:bg-gray-100">Pathology</a>
                  </li>
                  <li>
                    <a href="#Nursing" className="block px-4 py-2 hover:bg-gray-100">Nursing Station</a>
                  </li>
                  <li>
                    <a href="#Inventory" className="block px-4 py-2 hover:bg-gray-100">Inventory Module</a>
                  </li>
                  <li>
                    <a href="#Pharmacy" className="block px-4 py-2 hover:bg-gray-100">Pharmacy Module</a>
                  </li>
                  <li>
                    <a href="#TPA" className="block px-4 py-2 hover:bg-gray-100">TPA Module</a>
                  </li>
                  <li>
                    <a href="#MRD" className="block px-4 py-2 hover:bg-gray-100">MRD Module</a>
                  </li>
                  <li>
                    <a href="#Other" className="block px-4 py-2 hover:bg-gray-100">Other Module</a>
                  </li>
                </ul>
              </div>
            </li>
            <li className="hover:text-red-900 text-base whitespace-nowrap">
              <a href="#contact-us">Contacts</a>
            </li>
            <button className="signup-btn rounded-lg px-3 py-2 bg-gradient-to-r from-black to-red-500 text-white mb-2 whitespace-nowrap opacity-50 hover:opacity-100 hidden lg:block">
              <a href="https://wa.me/message/OMIG2SEK2XVVD1" className="block">GET STARTED</a>
            </button>
          </ul>
        </div>
        <div className="toggle-button md:block lg:hidden w-10 h-10 mr-2 mt-4" onClick={toggleMenu}>
          <i className="fas fa-bars"></i>
        </div>
      </nav>
    </header>
  );
};

export default Navbar;
