import React, { useState } from 'react';
import './Animation.css';
import { useInView } from 'react-intersection-observer';
import { ArrowCircleRightIcon, ClipboardListIcon, HeartIcon, ShieldCheckIcon, BeakerIcon, BriefcaseIcon, CurrencyDollarIcon, ClipboardCheckIcon, UserGroupIcon } from '@heroicons/react/outline'; // Import icons from Heroicons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThermometer, faXRay } from '@fortawesome/free-solid-svg-icons';

const Middle = () => {
    const { ref, inView } = useInView({
        triggerInfinity: true,
        threshold: 0.1, // Trigger when 10% of the section is visible
    });

    return (
        <div ref={ref} className={`transition-transform duration-1000 ${inView ? 'translate-y-0' : 'translate-y-10'}`}>
            <section id="services" className='bg-slate-50 bg-opacity-80'>
                <div>
                    <h1 className="font-bold text-red-900 text-center text-4xl py-8 opacity-80">Bhesajam HIMS Modules</h1>
                    <p className="text-center mx-12 opacity-70">Bhesajam Hospital Software accommodates a range of healthcare settings, from small nursing homes with 10 beds to large hospitals exceeding 2000 beds. This versatile healthcare software suite is available in both client-server and cloud-based versions.</p>
                </div>

                <div id="servicesCarousel" className="max-w-[1200px] mx-auto py-[50px] grid sm:grid-cols-1 lg:grid-cols-3 gap-6">
                    <div className="shadow-lg p-4 text-center bg-gradient-to-r from-white to-orange-300 text-white opacity-100 hover:opacity-100 relative service-box">
                        <div className="group flex flex-col items-center justify-center">
                            <ArrowCircleRightIcon className="h-8 w-8 mb-2 text-red-900 opacity-90  group-hover:text-red-900" />
                            <h3 className=" text-red-900 opacity-90 text-xl font-bold  group-hover:text-red-900" id='Patient'>Patient Appointment</h3>
                            <br />
                            <p className="text-gray-600  group-hover:text-red-900">Patients can schedule appointments at the hospital desk or online via the hospital portal or app. They can select a consultant based on availability, make payments, and receive appointment notifications on their mobile devices.</p>
                        </div>
                    </div>

                    <div className="shadow-lg p-4 text-center bg-gradient-to-r from-white to-red-300 text-white  opacity-100 hover:opacity-100 relative service-box">
                        <div className="group flex flex-col items-center justify-center">
                            <ClipboardListIcon className="h-8 w-8 mb-2  text-red-900 opacity-90  group-hover:text-red-900" />
                            <h3 className=" text-red-900 opacity-90 text-xl font-bold  group-hover:text-red-900" id='OPD'>OPD Module</h3>
                            <br />
                            <p className="text-gray-600  group-hover:text-red-900">The OPD Management module streamlines the entire outpatient cycle, from patient registration and queue management to billing, consultant prescriptions, and day care scheduling, enhancing efficiency for patients and healthcare providers.</p>
                        </div>
                    </div>

                    <div className="shadow-lg p-4 text-center bg-gradient-to-r from-white to-green-300 text-white  opacity-100 hover:opacity-100 relative service-box">
                        <div className="group flex flex-col items-center justify-center">
                            <ClipboardListIcon className="h-8 w-8 mb-2  text-red-900 opacity-90  group-hover:text-red-900" />
                            <h3 className=" text-red-900 opacity-90 text-xl font-bold  group-hover:text-red-900" id='IPD'>IPD Module</h3>
                            <br />
                            <p className="text-gray-600  group-hover:text-red-900">The Indoor Patient Management module efficiently manages the admission, discharge, and transfer processes, nursing care, patient billing, and discharge summaries.</p>
                        </div>
                    </div>

                    <div className="shadow-lg p-4 text-center bg-gradient-to-r from-white to-blue-300 text-white  opacity-100 hover:opacity-100 relative service-box">
                        <div className="group flex flex-col items-center justify-center">
                            <FontAwesomeIcon icon={faXRay} className="h-8 w-8 mb-2  text-red-900 opacity-90  group-hover:text-red-900" />
                            <h3 className=" text-red-900 opacity-90 text-xl font-bold  group-hover:text-red-900" id='Radiology'>Radiology</h3>
                            <br />
                            <p className="text-gray-600  group-hover:text-red-900">This module encompasses specialties like X-Ray, Ultrasound, CT Scan, MRI, managing the entire radiology process from registration and test scheduling to generating comprehensive test reports.</p>
                        </div>
                    </div>

                    <div className="shadow-lg p-4 text-center bg-gradient-to-r from-white to-orange-300 text-white  opacity-100 hover:opacity-100 relative service-box">
                        <div className="group flex flex-col items-center justify-center">
                            <HeartIcon className="h-8 w-8 mb-2  text-red-900 opacity-90  group-hover:text-red-900" />
                            <h3 className=" text-red-900 opacity-90 text-xl font-bold  group-hover:text-red-900" id='OT'>OT Module</h3>
                            <br />
                            <p className="text-gray-600  group-hover:text-red-900">The O.T. Management module assists in booking operating theaters, scheduling surgeries, recording surgical notes, and managing pre and post-anesthesia follow-ups and consents.</p>
                        </div>
                    </div>

                    <div className="shadow-lg p-4 text-center bg-gradient-to-r from-white to-red-300 text-white  opacity-100 hover:opacity-100 relative service-box">
                        <div className="group flex flex-col items-center justify-center">
                            <ShieldCheckIcon className="h-8 w-8 mb-2  text-red-900 opacity-90  group-hover:text-red-900" />
                            <h3 className=" text-red-900 opacity-90 text-xl font-bold  group-hover:text-red-900" id='Pathology'>Pathology</h3>
                            <br />
                            <p className="text-gray-600  group-hover:text-red-900">The Laboratory module manages OP/IP patient test bookings, sample collection, test report preparation, lab inventory, and interfaces with lab machines for automated test result data capture.</p>
                        </div>
                    </div>

                    <div className="shadow-lg p-4 text-center bg-gradient-to-r from-white to-green-300 text-white  opacity-100 hover:opacity-100 relative service-box">
                        <div className="group flex flex-col items-center justify-center">
                            <BeakerIcon className="h-8 w-8 mb-2  text-red-900 opacity-90  group-hover:text-red-900" />
                            <h3 className=" text-red-900 opacity-90 text-xl font-bold  group-hover:text-red-900" id='Nursing'>Nursing Station</h3>
                            <br />
                            <p className="text-gray-600  group-hover:text-red-900">The Nursing Care module oversees nursing station operations within the hospital, providing a centralized platform for nursing staff to deliver enhanced patient care and access information across departments.</p>
                        </div>
                    </div>

                    <div className="shadow-lg p-4 text-center bg-gradient-to-r from-white to-blue-300 text-white  opacity-100 hover:opacity-100 relative service-box">
                        <div className="group flex flex-col items-center justify-center">
                            <BriefcaseIcon className="h-8 w-8 mb-2  text-red-900 opacity-90  group-hover:text-red-900" />
                            <h3 className=" text-red-900 opacity-90 text-xl font-bold  group-hover:text-red-900" id='Inventory'>Inventory Module</h3>
                            <br />
                            <p className="text-gray-600  group-hover:text-red-900">The Inventory Module tracks procurement and supply of hospital consumables, equipment, and other inventory items to ensure efficient management of hospital stores.</p>
                        </div>
                    </div>

                    <div className="shadow-lg p-4 text-center bg-gradient-to-r from-white to-orange-300 text-white  opacity-100 hover:opacity-100 relative service-box">
                        <div className="group flex flex-col items-center justify-center">
                            <FontAwesomeIcon icon={faThermometer} className="h-8 w-8 mb-2  text-red-900 opacity-90  group-hover:text-red-900" />
                            <h3 className=" text-red-900 opacity-90 text-xl font-bold  group-hover:text-red-900" id='Pharmacy'>Pharmacy Module</h3>
                            <br />
                            <p className="text-gray-600  group-hover:text-red-900">The Bhesajam pharmacy module manages indents, ordering, purchase, sales, and stock of medicines. It includes tools for expiry checks, slow-moving items, non-moving items, and ABC/FSN analysis reports to optimize pharmacy store operations.</p>
                        </div>
                    </div>

                    <div className="shadow-lg p-4 text-center bg-gradient-to-r from-white to-red-300 text-white  opacity-100 hover:opacity-100 relative service-box">
                        <div className="group flex flex-col items-center justify-center">
                            <CurrencyDollarIcon className="h-8 w-8 mb-2  text-red-900 opacity-90  group-hover:text-red-900" />
                            <h3 className=" text-red-900 opacity-90 text-xl font-bold  group-hover:text-red-900" id='TPA'>TPA Module</h3>
                            <br />
                            <p className="text-gray-600  group-hover:text-red-900">The TPA module manages billing and settlement processes for patients covered by third-party payers such as insurance companies or employers, streamlining financial transactions.</p>
                        </div>
                    </div>

                    <div className="shadow-lg p-4 text-center bg-gradient-to-r from-white to-green-300 text-white  opacity-100 hover:opacity-100 relative service-box">
                        <div className="group flex flex-col items-center justify-center">
                            <UserGroupIcon className="h-8 w-8 mb-2  text-red-900 opacity-90  group-hover:text-red-900" />
                            <h3 className=" text-red-900 opacity-90 text-xl font-bold  group-hover:text-red-900" id='MRD'>MRD Module</h3>
                            <br />
                            <p className="text-gray-600  group-hover:text-red-900">The MRD module manages patient file records and tracks file movement between departments, ensuring efficient document management within the hospital.</p>
                        </div>
                    </div>

                    <div className="shadow-lg p-4 text-center bg-gradient-to-r from-white to-blue-300 text-white  opacity-100 hover:opacity-100 relative service-box">
                        <div className="group flex flex-col items-center justify-center">
                            <ClipboardCheckIcon className="h-8 w-8 mb-2  text-red-900 opacity-90  group-hover:text-red-900" />
                            <h3 className=" text-red-900 opacity-90 text-xl font-bold  group-hover:text-red-900" id='Other'>Other Modules</h3>
                            <br />
                            <p className="text-gray-600 group-hover:text-red-900">Additional modules include Finance, Asset & Machine Maintenance, Ambulance services, Housekeeping, Dietary management, Biomedical Waste disposal, C.S.S.D, and more to support comprehensive hospital management.</p>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Middle;
